<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="require('@/assets/images/logos/logo.svg')"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              BO Planning
            </h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
              Bienvenue encore une fois !
          </p>
          <p class="mb-2">
Veuillez vous connecter à votre compte
         </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="email"
              outlined
              label="Email"
              placeholder="john@example.com"
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Mot de passe"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox
                label="Se souvenir de moi"
                hide-details
                class="me-3 mt-1"
              >
              </v-checkbox>
            </div>
            <v-btn
              block
              color="primary"
              class="mt-6"
               @click="login"
            >
              Connexion
            </v-btn>
          </v-form>
          <br>
          <p class ="mb-2">{{ message }}</p>
        </v-card-text>

      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import AuthAPI from '../../api/AuthAPI'

export default {
  setup() {
    const isPasswordVisible = ref(false)
    const email = ref('')
      const password = ref('')

      return {
        isPasswordVisible,
        email,
        password,
        message:'',
        icons: {
          mdiEyeOutline,
          mdiEyeOffOutline,
        },
      }
    },
    methods: {
      login() {
          AuthAPI.signIn({
          email: this.email,
          password: this.password
          })
          .then(response => {

            if ( response.data != null) {
                        localStorage.setItem(
            "user",
            JSON.stringify({
            email: this.email,
          }));
          localStorage.setItem(
            "token", response.data.token);
            console.log(localStorage.getItem('user'));
            this.$router.push({ name: "Planning projects" });
            this.message = 'Connexion réussite ! ';
            setTimeout( () => this.$router.push({ path: '/'}), 1000);
          } else {
            throw Error('Server response error');
          }
        })
        .catch(e => {
          const error = (e && e.message) || 'Internal server error';
          this.message = 'Erreur de connexion:' + error;
        });
    }
  }
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
