import API from './axiosClient';
import config from './../config';

const URL =  config.apiURL;

function signIn(data) {
    return API.post(URL + '/sign-in', data);
}
export default {
    signIn
};